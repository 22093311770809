/*
 * Send message
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from '../../../service/contact.service';

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss']
})
export class SendMessageComponent implements OnInit {

   sendMessageForm: FormGroup;
   sendMessageData: any;
   flash = false;
   mess: string = '';

  constructor(private api: ContactService, private formBuilder : FormBuilder) {

      this.sendMessageForm = this.formBuilder.group({
        first_name : [null, [Validators.required] ],
        last_name  : [null, [Validators.required] ],
        email      : [null, [Validators.email] ],
        message   : [null, [Validators.required] ]
      });
   }

   ngOnInit() {
   }

   /*
    * sendMessage
    */
   sendMessage(values:any)
   {
      if(this.sendMessageForm.valid)
      {

        this.api.postContact(values).subscribe((res: {data: {state: number, msg: string}}) => {
          if (res.data.state == 201) {
            this.flash = true;
          } else if (res.data.state == 503) {
            this.flash = false;
          } else if (res.data.state == 400) {
            this.flash = false;
          }
          this.mess = res.data.msg;
          this.sendMessageForm.reset();
          setTimeout(function () {
            this.mess = '';
          }, 3000);
        });

        // this.api.postContact(values).subscribe(
        //   (res:ResponseData) => {
        //     console.log(res);
        //      if (res.data.state == 201) {
        //          this.flash = true;
        //      } else if (res.data.state == 503) {
        //          this.flash = false;
        //      } else if (res.data.state == 400) {
        //        this.flash = false;
        //      }
        //     this.mess = res.data.msg;
        //     this.sendMessageForm.reset();
        //   },
        //   (err) => {

        //     this.flash = false;
        //     this.mess = 'Impossible de se connecter à internet.'
        //     this.sendMessageForm.reset();
        //   }
        // )

      } else{
        console.log("Error!");
      }
   }

}
