import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { ChkService } from '../service/chk.service';
declare var $ : any;

@Component({
  selector: '[angly-footer2]',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.scss']
})
export class Footer2Component implements OnInit {

   /* Variables */
   footer2Menu : any;
   footerLogo  : any;

   constructor(public menuItems: MenuItems, @Inject(DOCUMENT) private document: Document, private service:ChkService, public router: Router) { }

   ngOnInit() {

      this.footer2Menu = this.menuItems.getFooter2();

      this.service.getFooterLogoList().
         subscribe(response => {this.footerLogo = response},
                   err      => console.log(err),
                   ()       => this.footerLogo
               );
   }

   subscribeFormClasses : any = {rowClass:"row", fieldClass:"col-sm-12 col-md-6"}

}
