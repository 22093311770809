import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';

@Component({
  selector: 'app-contenu-local',
  templateUrl: './contenu-local.component.html',
  styleUrls: ['./contenu-local.component.css']
})
export class ContenuLocalComponent implements OnInit {

  constructor(
    private pageTitleService: PageTitleService,
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle('Contenu-Local');
    /* Page subTitle */
    this.pageTitleService.setSubTitle('');
  }

}
