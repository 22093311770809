import { Component, OnInit, OnDestroy, ViewChild, HostListener, Inject, ElementRef, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { trigger, state, animate, style, transition, keyframes } from '@angular/animations';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { ScrollbarComponent } from 'ngx-scrollbar';
import * as jQuery from 'jquery';
import 'jquery-knob';
import { ApiData } from '../service/api-data.service';
declare var $: any;

export const COUNTRY_TRADUCTION = {
  CI: { en: 'Ivory Coast', fr: 'Cote d\'ivoire' }
};
@Component({
  selector: 'angly-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('toggleElement', [
      state(
        'open',
        style({ right: '280px' })
      ), // Les etats ou effet d'animation voulue
      state(
        'closed',
        style({ right: '-280px' })
      ), // Les etats ou effet danimation voulue

      transition('closed <=> open', [
        animate('1000ms cubic-bezier(1, 0, 0, 1)') // Utiliser le site easings.net pour les animations et transistions
      ])
    ])
  ]
})
export class MainComponent implements OnInit {
  private _router: Subscription;
  url: string;
  private _mediaSubscription: Subscription;
  private _routerEventsSubscription: Subscription;
  isHome: boolean;
  isBlog: boolean;
  isCommon: boolean;
  fixedHeaderClass: boolean;
  dg: string;
  open = false;
  toggleElement: String = 'open';

  /* Variables */
  headerTitle: string;
  headerSubTitle: string;
  featuredPost: any;
  today: number = Date.now();

  /* Stats */
  brut: any;
  brent: any;
  valBoursieres: any;
  motDg: any;

  /* Bourse */
  bourse: string = '';

  defaultCarousel = [
    {'name': 'Stockage et Transport', 'img': '2.png'},
    {'name': 'Bloc Pétrolier', 'img': '4.png'},
    {'name': 'Fiscalité de l’Amont Pétrolier', 'img': '5.png'},
    {'name': 'Amont Pétrolier', 'img': '8.png'},
    {'name': 'Aval Pétrolier', 'img': '6.png'},
    {'name': 'Chaîne de Production', 'img': '7.png'},
    {'name': 'Prix à la Pompe', 'img': '3.png'},
    {'name': 'La Direction Générale des Hydrocarbures est ' +
      'garante de la production et de l\'achéminement des Hydrocarbures dans tout le pays.', 'img': '1.png'}
  ];

  imagesCarousel: any[];

  @ViewChild(ScrollbarComponent) scrollRef: ScrollbarComponent;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private pageTitleService: PageTitleService,
    private service: ChkService,
    public apiData: ApiData,
    public router: Router
  ) {

    this.isHome = true;
    this.isBlog = false;
    this.isCommon = false;
    this.fixedHeaderClass = false;
    this.dg = 'show';

    /* page title.*/
    this.pageTitleService.title.subscribe((val: string) => {
      this.headerTitle = val;
    });

    /* page subTitle. */
    this.pageTitleService.subTitle.subscribe((val: string) => {
      this.headerSubTitle = val;
    });

    // this.service.getFeaturedPost()
    //   .subscribe(
    //     response => { this.featuredPost = response; },
    //     err      => console.log(err),
    //     ()       => this.featuredPost
    //   );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).gtag('set', 'page', event.urlAfterRedirects);
        (<any>window).gtag('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (this.scrollRef) {
          this.scrollRef.scrollToTop();
        }
      });
    document.addEventListener('scroll', this.scroll, true);
    this.toggle();
    this.detectScreen();
    // Bourse
    this.getBourseContent();
    // Brut
    this.setPetrol(5);
    // Brent
    this.setPetrol(6);
    // Autres Valeurs
    this.getValeurBoursiere();
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  addToggleClass() {
    $('body').toggleClass('rtl-enable');
  }

  scroll(e) {
    if (e.target.scrollTop > 300) {
      $('#back-top').fadeIn(0);
    } else {
      $('#back-top').fadeOut(0);
    }
  }

  scrollToTop() {
    this.scrollRef.scrollToTop(1500);
  }

  getBourseContent() {
    const params = "?zone=price&collection=getAll&section=select data";
    this.apiData.get(params).subscribe(
      (rep: any) => {
        const bourseData =
          rep.data.state === 202 && rep.data.valid === true
            ? rep.data.data
            : [];
        this.formatData(bourseData);
      },
      error => {
        this.bourse = '';
      }
    );
  }

  formatData(bourse) {
    Object.keys(bourse).forEach(key => {
      const item: any = {},
        elt = bourse[key];
      item.countryCode = key;
      if (COUNTRY_TRADUCTION[item.countryCode]) {
        item.country = COUNTRY_TRADUCTION[item.countryCode].fr;
      } else {
        item.country = bourse[key][0].country.libelle;
      }
      item.date = bourse[key][0].date;
      const gasolines = elt.filter(x => {
        if (x.gasoline || x.gasoline === null) {
          return x;
        }
      });
      item.gasoline = gasolines[0];
      const diesels = elt.filter(x => {
        if (x.diesel || x.diesel === null) {
          return x;
        }
      });
      item.diesel = diesels[0];
      const lpgs = elt.filter(x => {
        if (x.lpg || x.lpg === null) {
          return x;
        }
      });
      item.lpg = lpgs[0];
      const exrates = elt.filter(x => {
        if (x.exrate || x.exrate === null) {
          return x;
        }
      });
      item.exrate = exrates[0];
      const brents = elt.filter(x => {
        if (x.brent || x.brent === null) {
          return x;
        }
      });
      item.brent = brents[0];
      const wtis = elt.filter(x => {
        if (x.wti || x.wti === null) {
          return x;
        }
      });
      item.wti = wtis[0];

      const opecs = elt.filter(x => {
        if (x.opec || x.opec === null) {
          return x;
        }
      });
      item.opec = opecs[0];

      const worldgasolines = elt.filter(x => {
        if (x.worldgasoline || x.worldgasoline === null) {
          return x;
        }
      });
      item.worldgasoline = worldgasolines[0];
      if (item.worldgasoline && item.worldgasoline.worldgasoline) {
        item.worldgasoline.worldgasoline = (item.worldgasoline
          .worldgasoline as number).toFixed(2);
      }
      const worlddiesels = elt.filter(x => {
        if (x.worlddiesel || x.worlddiesel === null) {
          return x;
        }
      });
      item.worlddiesel = worlddiesels[0];

      if (item.worlddiesel && item.worlddiesel.worlddiesel) {
        item.worlddiesel.worlddiesel = (item.worlddiesel
          .worlddiesel as number).toFixed(2);
      }
      const a = [];
      this.bourse += item.country + ' (';
      if (item.gasoline) {
        a.push('GAZOIL : ' + item.gasoline.gasoline + ' ' + item.gasoline.currency);
      }
      if (item.diesel) {
        a.push('SUPER : ' + item.diesel.diesel + ' ' + item.diesel.currency);
      }
      if (item.lpg) {
        a.push('GAZ BUTANE : ' + item.lpg.lpg + ' ' + item.lpg.currency)
      }
      this.bourse += a.join(', ');
      this.bourse +=  ') - ';
    });
  }

  setPetrol(val) {
    this.service.getPetrole()
      .subscribe(
        (rep: any) => {
          this.brut = rep.brut;
          this.brent = rep.brent;
        },
        err => console.log(err),
        ()  => void(0)
      );
  }

  getValeurBoursiere() {
    const params = '?section=select data&collection=valboursier&zone=zone' ;

    this.apiData.get(params)
      .subscribe(
        (rep: any) => {
          this.valBoursieres = (rep.data.state === 202 && rep.data.valid === true) ? rep.data.data.bour : [];
          this.motDg = (rep.data.state === 202 && rep.data.valid === true) ? rep.data.data.mot : {};
          this.motDg.CONTENU = this.motDg.CONTENU.split('</p>')[0] + '</p>';
        },
        error => {
          this.valBoursieres = [];
          this.motDg = {};
        });

  }

  toggle() {
    this.open = !this.open;
    if (this.open) {
      this.toggleElement = 'open';

    } else {
      this.toggleElement = 'closed';
    }
  }

  startKnob() {
    jQuery('.dial').knob({
      width: 50,
      height: 50,
      min: 0,
      step: 0.01,
      fgColor: '#0c9443',
      readOnly: true,
      lineCap : 'rounded'
    });
  }

  setKnob() {
    this.startKnob();

    jQuery('.counterOne').val(this.brent).trigger('change');
    jQuery('.counterTwo').val(this.brut).trigger('change');

    for (let i = 0; i < this.valBoursieres.length; i++) {
      jQuery('.counter-' + (i + 1)).trigger('configure', {max: this.valBoursieres[i].QUOTAS});
      jQuery('.counter-' + (i + 1)).val(this.valBoursieres[i].VALEUR).trigger('change');
    }
  }

  detectScreen() {
    const array: any[] = [];

    for (let i = 0; i < this.defaultCarousel.length; i ++) {
      if (window.innerWidth >= 1366) {
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/1366x500/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      } else if (window.innerWidth < 1366 && window.innerWidth >= 991 ) {
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/991x362/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      } else if (window.innerWidth < 991 && window.innerWidth >= 833 ) {
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/833x302/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      } else if (window.innerWidth < 833 && window.innerWidth >= 767 ) {
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/767x272/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      } else if (window.innerWidth < 767 && window.innerWidth >= 575 ) {
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/575x210/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      } else if (window.innerWidth < 575 && window.innerWidth >= 490 ) {
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/490x180/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      } else if (window.innerWidth < 490 ) {
        this.dg = '';
        array[i] = {
          'name' : this.defaultCarousel[i].name,
          'url' : 'assets/img/490x180/' + this.defaultCarousel[i].img,
          'alt' : 'http://dghstatistiques.ci',
        };
      }
    }
    this.imagesCarousel = array;
  }
}
