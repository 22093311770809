import { Injectable } from "@angular/core";

/*
 * Menu interface
 */
export interface Menu {
  state: string;
  name?: string;
  type?: string;
  icon?: string;
  children?: ChildrenItems[];
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

const HEADERMENUITEMS = [
  {
    state: "home",
    name: "Accueil",
    type: "link"
  },
  {
    state: "dgh",
    name: "La DGH",
    type: "sub",
    icon: "fa fa-caret-down",
    children: [
      { state: "presentation", name: "Présentation", type: "link" },
      { state: "mot-dg", name: "Mot du DG", type: "link" },
      {
        state: "direction-exploitation-production-hydrocarbures",
        name:
          "Direction de l'Exploration et de la Production des Hydrocarbures",
        type: "link"
      },
      {
        state:
          "direction-approvisionnement-raffinage-distribution-hydrocarbures",
        name:
          "Direction de l'Approvisionnement, du Raffinage et de la Distribution",
        type: "link"
      },
      {
        state: "direction-suivi-reglementation-hydrocarbures",
        name: "Direction du Suivi et de la Réglementation des Hydrocarbures",
        type: "link"
      },
      {
        state: "services-evaluation-suivi-economique-statistique",
        name:
          "Services de l'Evaluation, du Suivi Economique et de la statistique",
        type: "link"
      }
    ]
  },
  {
    state: "amont-petrolier",
    name: "Amont Pétrolier",
    type: "sub",
    icon: "fa fa-caret-down",
    children: [
      { state: "bloc", name: "Blocs pétroliers", type: "link" },
      {
        state: "production",
        name: "Production de pétrole brut et de Gaz naturel",
        type: "link"
      },
      {
        state: "fiscalite",
        name: "Fiscalité de l’Amont Pétrolier",
        type: "link"
      }
    ]
  },
  {
    state: "aval-petrolier",
    name: "Aval Pétrolier",
    type: "sub",
    icon: "fa fa-caret-down",
    children: [
      {
        state: "production-prix",
        name: "Production de produits pétroliers",
        type: "link"
      },
      {
        state: "consomation-nationale",
        name: "Consomation Nationale",
        type: "link"
      },
      {
        state: "stockage-transport",
        name: "Stockage et Transport",
        type: "link"
      }
    ]
  },
  {
    state: "documentation",
    name: "Documentation",
    type: "link"
  },
  {
    state: "station-service",
    name: "Localisation Stations",
    type: "link"
  },

  {
    state: "contact",
    name: "Contact",
    type: "link"
  },

  {
    state: "contenu-local",
    name: "Contenu Local",
    type: "link"
  }
];

const FOOTERMENU = [
  {
    state: "http://www.mpeder.ci",
    name: "MPEDER",
    type: "link-extern"
  },
  {
    state: "http://www.cnitie.ci",
    name: "CNITIE",
    type: "link-extern"
  }
];

const EXPLOREMENU = [
  {
    state: "http://www.petroci.ci",
    name: "PETROCI",
    type: "link-extern"
  },
  {
    state: "http://www.sir.ci",
    name: "SIR",
    type: "link-extern"
  },
  {
    state: "http://www.gestoci.ci",
    name: "GESTOCI",
    type: "link-extern"
  }
];

const FOOTERMENU2 = [
  {
    state: "home",
    name: "Accueil",
    type: "link"
  },
  {
    state: "aval-petrolier",
    name: "Aval Pétrolier",
    type: "link"
  },
  {
    state: "amont-petrolier",
    name: "Amont Pétrolier",
    type: "link"
  },
  {
    state: "documentation",
    name: "Documentation",
    type: "link"
  },
  {
    state: "contact",
    name: "Contact",
    type: "link"
  }
];

@Injectable()
export class MenuItems {
  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return HEADERMENUITEMS;
  }

  /*
   * Get footer menu
   */
  getFooterMenu(): Menu[] {
    return FOOTERMENU;
  }

  /*
   * Get the explore menu
   */
  getExploreMenu(): Menu[] {
    return EXPLOREMENU;
  }

  /*
   * Get the footer2
   */
  getFooter2(): Menu[] {
    return FOOTERMENU2;
  }
}
