import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'angly-locate',
  templateUrl: './locate.component.html',
  styleUrls: ['./locate.component.scss']
})
export class LocateComponent implements OnInit {

  /* Variables */
  //locateContent : any;

  constructor(private pageTitleService: PageTitleService, private service:ChkService) {
    
    /* Page title */
    this.pageTitleService.setTitle(" Localisation ");

    /* Page subTitle */
    this.pageTitleService.setSubTitle(" Mapping des Stations répertoriées. ");

   /* this.service.getLocateContent().
      subscribe(response => {this.locateContent = response},
          err => console.log(err),
          () => this.locateContent
      );*/
  }

  ngOnInit() {
  }


}
