import { Component, OnInit } from '@angular/core';
import { ChkService } from '../service/chk.service';
import { ApiData } from '../service/api-data.service';
declare var $ : any;

@Component({
  selector: 'angly-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /* Variables */
  homeContent    : any;
  services       : any;
  projectGallary : any;
  posts          : any;
  team           : any;
  pricingContent : any;
  contact        : any;
  videoContent   : any;
  mobileFeatured : any;
  testimonial    : any;

  constructor(private apiData:ApiData) {
  }

  ngOnInit() {
    this.getHomeContent();
  }

  getHomeContent() {
    const params = '?section=select data&collection=accueil&zone=zone';

    this.apiData.get(params)
      .subscribe(
        (rep: any) => {
          this.homeContent = (rep.data.state === 202 && rep.data.valid === true) ? rep.data.data : [];
        },
        error => {
          this.homeContent = [];
        });
  }


  /*
   * getContent is used for get the home page content.
   * Used variables is videoContent and mobileFeatured.
   */
   getContent(content)
   {
      this.videoContent    = content.video_content;
      this.mobileFeatured  = content.mobile_featured;
   }

   subscribeFormClasses : any = {rowClass:"row", fieldClass:"col-sm-12 col-md-6"}



}
