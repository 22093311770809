import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { ApiData } from '../../../service/api-data.service';
declare var $: any;

@Component({
  selector: 'angly-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

   @Input() width: any = '800';
   @Input() height: any = '580';

  map: any = 'https://maps.google.com/maps?q=';

  constructor(private apiData: ApiData, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    const data = `
   		.mapouter {
     		overflow: hidden;
     		height: ` + this.height + `px;
     		width: ` + this.width + `px;
   		}
   		.gmap_canvas {
     		background: none!important;
        height: ` + this.height + `px;
     		width: ` + this.width + `px;
     	}
    `;

    $(document).ready(function() {
      $('style').append(data);
    });

    this.getContactContent();
  }

  getContactContent() {
    const params = '?section=select data&collection=contact&zone=zone' ;

    this.apiData.get(params)
      .subscribe(
        (rep: any) => {
          const url = (rep.data.state === 202 && rep.data.valid === true) ? `${rep.data.data.cont.ADRESSMAP}` : '';

          this.map += url + '&output=embed';
        },
        error => {
          this.map += '&output=embed';
        });
  }

}
