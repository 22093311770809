/**
 * Created by stephaneouattara on 16/01/2018.
 */

import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';

@Injectable()
export class ContactService {
  contactUrl: string;

  constructor(public http: HttpClient) {
    this.contactUrl = 'http://dghstatistiques.ci/assets/api/';
  }

  postContact(values): Observable<any> {
    const body = new HttpParams()
      .set(`surname`, values.last_name)
      .set(`firstname`, values.first_name)
      .set(`email`, values.email)
      .set(`message`, values.message)
      .set(`section`, 'send mail');

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(this.contactUrl, body.toString(), { headers }).pipe(
      map(this.extractData)
    );
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
}
