import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { DOCUMENT } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiData } from '../service/api-data.service';

declare var $ : any;

@Component({
  selector: '[angly-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /* Variables */
  footerExploreMenu: any = [];
  footerCompanyMenu: any = [];
  contact: any;
  social: any;

  constructor(public menuItems: MenuItems, @Inject(DOCUMENT) private document: Document, public router: Router, private apiData: ApiData) { }

  ngOnInit() {
    this.getFooterContent();
  }

  getFooterContent() {
    const params = '?section=select data&collection=footer&zone=zone' ;

    this.apiData.get(params)
      .subscribe(
        (rep: any) => {
          this.footerCompanyMenu = (rep.data.state === 202 && rep.data.valid === true) ? rep.data.data.pied : [];
          this.footerExploreMenu = (rep.data.state === 202 && rep.data.valid === true) ? rep.data.data.detpied : [];
          this.contact = rep.data.data.piedcont[0];
          this.social = rep.data.data.social;
        },
        error => {
          this.footerCompanyMenu = [];
          this.footerExploreMenu = [];
          this.contact = {};
          this.social = [];
        });
  }

  /*
   * Object of call to action button.
   */
  buttonDetails = { url:'pricing', title:'Get Started Today!'}


  /*
   * Socail media information.
   */
  socialDetails : any = [
    { url: '', icon : 'fa fa-facebook'},
    { url: '', icon : 'fa fa-twitter'},
    { url: '', icon : 'fa fa-google'},
    { url: '', icon : 'fa fa-linkedin'},
    { url: '', icon : 'fa fa-instagram'}
  ]

  socialsClasses : any = {ulClass:"mb-0", liClass:"", linkClass:"nav-link"}

}
