import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiData {
  url: string;
  headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.url = environment.url;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'});
  }

  public get(params) {
    const headers = this.headers;

    return this.http.request('get', this.url + params + '&method=GET', { headers, responseType: 'json' })
      .pipe(map(data => data));
  }

  public post(body: HttpParams) {
    const headers = this.headers;
    body = body.append(`method`, 'POST');

    return this.http.request('post', this.url, { body, headers, responseType: 'json' })
      .pipe(map(data => data));
  }

  public put(body: HttpParams) {
    const headers = this.headers;
    body = body.append(`method`, 'PUT');

    return this.http.request('put', this.url, { body, headers, responseType: 'json' })
      .pipe(map(data => data));
  }

  public delete(body: HttpParams) {
    const headers = this.headers;
    body = body.append(`method`, 'DELETE');

    return this.http.request('delete', this.url, { body, headers, responseType: 'json' })
      .pipe(map(data => data));
  }
}
