import { Component, OnInit, HostListener, Inject } from "@angular/core";
import { MenuItems } from "../core/menu/menu-items/menu-items";
import { Router } from "@angular/router";
import { ClickOutside } from "../core/directive/click-outside.directive";
import { ApiData } from "../service/api-data.service";
declare var $: any;

@Component({
  selector: "[angly-menu]",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit {
  searchactive: boolean = false;
  menus: any[];

  constructor(
    public menuItems: MenuItems,
    public router: Router,
    public apiData: ApiData
  ) {}

  ngOnInit() {
    this.getMainMenu();
  }

  getMainMenu() {
    const params = "?section=select data&collection=menu&zone=zone";

    this.apiData.get(params).subscribe(
      (rep: any) => {
        this.menus =
          rep.data.state === 202 && rep.data.valid === true
            ? rep.data.data
            : [];
      },
      error => {
        this.menus = [];
      }
    );
  }

  searchActiveFunction() {
    this.searchactive = !this.searchactive;
  }

  onClickOutside(event: Object) {
    if (event && event["value"] === true) {
      this.searchactive = false;
    }
  }

  public removeCollapseInClass() {
    $("#navbarCollapse").removeClass("show");
  }
}
