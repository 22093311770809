import { NgModule, enableProdMode, ApplicationRef, LOCALE_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SlickModule } from 'ngx-slick';
import { DirectivesModule } from './core/directive/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UICarouselModule } from 'ui-carousel';
import { ScrollbarModule } from 'ngx-scrollbar';
import { NgMarqueeModule } from 'ng-marquee';

import localeFr from '@angular/common/locales/fr';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

/* Service */
import { ChkService } from './service/chk.service';
import { ContactService } from './service/contact.service';
import { ApiData } from './service/api-data.service';

/* components */
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
// import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { LocateComponent } from './locate/locate.component';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { WidgetsModule } from './widgets/widgets.module';
// import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
// import { SearchComponent } from './search/search.component';
import { Footer2Component } from './footer2/footer2.component';
import { SafePipe } from './pipe/safe.pipe';
import { ContenuLocalComponent } from './contenu-local/contenu-local.component';
import { ContenuLocalModule } from './contenu-local/contenu-module.module';
// import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';

registerLocaleData(localeFr);

enableProdMode();
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    // PricingComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    // FeaturesComponent,
    AboutComponent,
    // SearchComponent,
    Footer2Component,
    // sidebarWidgetsComponent,
    LocateComponent,
    SafePipe,
    ContenuLocalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule,
    WidgetsModule,
    MenuToggleModule,
    DirectivesModule,
    UICarouselModule,
    SlickModule.forRoot(),
    HttpClientModule,
    NgbModule,
    ScrollbarModule,
    NgMarqueeModule
    
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    MenuItems,
    PageTitleService,
    ChkService,
    ContactService,
    ApiData
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
