import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { ContactService } from '../service/contact.service';
import { ApiData } from '../service/api-data.service';

@Component({
  selector: 'angly-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  /* Variables */
  contact: any;

  constructor(
    private api: ContactService,
    private pageTitleService: PageTitleService,
    private service: ChkService,
    private apiData: ApiData
  ) { }

  ngOnInit() {
    this.getContactContent();
  }

  getContactContent() {
    const params = '?section=select data&collection=contact&zone=zone' ;

    this.apiData.get(params)
      .subscribe(
        (rep: any) => {
          this.contact = (rep.data.state === 202 && rep.data.valid === true) ? rep.data.data : [];
          /* Page title */
          this.pageTitleService.setTitle(this.contact.cont.LIBELLE);

          /* Page subTitle */
          this.pageTitleService.setSubTitle(this.contact.cont.SUBTEXT);
        },
        error => {
          this.contact = [];
        });
  }

}
