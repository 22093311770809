/*
 * Service grid structure
 * Used in another components.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: '[angly-serviceGrid]',
  templateUrl: './serviceGrid.component.html',
  styleUrls: ['./serviceGrid.component.scss'],
 /* animations: [
        trigger(
            'fadeInOut', [
                transition(':enter', [   // :enter is alias to 'void => *'
                    style({opacity:0}),
                    animate(1000, style({opacity:2}))
                ]),
                transition(':leave', [   // :leave is alias to '* => void'
                    animate(1000, style({opacity:0}))
                ])
            ])
      ]*/
})
export class ServiceGridComponent implements OnInit {

    //show: boolean = false;

  /*
   * serviceGridContent is a attribute.
   */
  @Input()  serviceGridContent:any;

  constructor() { }

  ngOnInit() {
  }


}
