import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";

import { MainComponent } from "./main/main.component";
import { HomeComponent } from "./home/home.component";
// import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from "./contact/contact.component";
import { ContenuLocalComponent } from "./contenu-local/contenu-local.component";
// import { FeaturesComponent } from './features/features.component';
// import { AboutComponent } from './about/about.component';
// import { SearchComponent } from './search/search.component';
// import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "home",
        component: HomeComponent
      },
      {
        path: "",
        loadChildren: "./blog/blog.module#BlogModule"
      },
      {
        path: "contact",
        component: ContactComponent
      },
      {
        path: "dgh",
        loadChildren: "./dgh/dgh.module#DghModule"
      },
      {
        path: "amont-petrolier",
        loadChildren: "./amont-petrolier/amont-petrolier.module#AmontModule"
      },
      {
        path: "aval-petrolier",
        loadChildren: "./aval-petrolier/aval-petrolier.module#AvalModule"
      },
      {
        path: "documentation",
        loadChildren: "./documentation/documentation.module#DocumentationModule"
      },
      {
        path: "station-service",
        loadChildren:
          "./stations-services/stations-services.module#StationServiceModule"
      },
      {
        path: "tarification",
        loadChildren: "./tarification/tarification.module#TarificationModule"
      },
      {
        path: "statistiques",
        loadChildren: "./statistiques/statistiques.module#StatistiquesModule"
      },
      {
        path: "declaration",
        loadChildren: "./declaration/declaration.module#DeclarationModule"
      },
      {
        path: "autorisation",
        loadChildren: "./autorisation/autorisation.module#AutorisationModule"
      },
      {
        path: "bourse",
        loadChildren: "./bourse/bourse.module#BourseModule"
      },
      {
        path: "contenu-local",
        component: ContenuLocalComponent
      } 
    ]
  },
  {
    path: "**",
    redirectTo: "home",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, { onSameUrlNavigation: "reload" })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
